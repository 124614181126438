<template>
  <div>
    <SectionHeading :content="heading.content" :extra_content="heading.extra" />

  </div>
</template>

<script>
import SectionHeading from "../components/SectionHeading.vue";
export default {
  name: "Dashboard",

  components: { SectionHeading },

  data: () => ({
    heading: {
      content: "Your Dashboard",
      extra: [{ type: "text", text: "Loaded in 0.0982sec", color: "#2ECC71" }],
    },

  }),
};
</script>
